import React, { useState, useContext } from 'react';
import { Box, Typography, Button, TextField, FormControl, Select, MenuItem, InputLabel, Grid, CircularProgress } from '@mui/material';
import { createResearchFromTemplate } from '../../services/api';
import { AppContext } from '../../context/AppContext';

/**
 * Шаг 3: выбор шаблона / кастомный бриф
 * 
 * Props:
 *  selectedProductId: string
 *  selectedTemplateId, setSelectedTemplateId
 *  customBrief, setCustomBrief
 *  templateDesign, setTemplateDesign
 *  templateName, setTemplateName
 *  templates: список шаблонов [{template_id, template_name, ...}]
 */
const WizardStep3Design = ({
  selectedProductId,
  selectedTemplateId, setSelectedTemplateId,
  customBrief, setCustomBrief,
  templateDesign, setTemplateDesign,
  templateName, setTemplateName,
  templates
}) => {
  const { setNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const handleSelectTemplate = async () => {
    if (!selectedTemplateId) {
      // сброс -> кастом
      setTemplateDesign(null);
      setTemplateName('');
      return;
    }
    if (!selectedProductId) {
      setNotification({ type: 'error', text: 'Сначала выберите продукт' });
      return;
    }
    setLoading(true);
    try {
      const resp = await createResearchFromTemplate(selectedTemplateId, selectedProductId);
      setTemplateDesign(resp.data.default_design);
      setTemplateName(resp.data.template_name);
      setNotification({ type: 'info', text: `Загружен шаблон: ${resp.data.template_name}` });
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при загрузке шаблона' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 3: Дизайн исследования</Typography>
      <Typography sx={{ mt: 1 }}>
        Вы можете выбрать готовый шаблон или заполнить кастомный бриф.
      </Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Выбор шаблона */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Шаблон</InputLabel>
            <Select
              value={selectedTemplateId}
              label="Шаблон"
              onChange={(e) => setSelectedTemplateId(e.target.value)}
            >
              <MenuItem value="">Кастомный бриф</MenuItem>
              {templates.map((t) => (
                <MenuItem key={t.template_id} value={t.template_id}>
                  {t.template_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <Button variant="contained" onClick={handleSelectTemplate} disabled={loading} fullWidth>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Применить'}
          </Button>
        </Grid>
      </Grid>

      {/* Кастомный бриф */}
      {!selectedTemplateId && (
        <Box sx={{ border: '1px solid #ccc', p: 2, mt: 3, borderRadius: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Что вы хотите узнать из этого исследования? *"
                fullWidth
                multiline
                rows={2}
                value={customBrief.rsr_objectives}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_objectives: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Какие проблемы или вопросы в вашем бизнесе вы хотите решить с помощью этого исследования? *"
                fullWidth
                multiline
                rows={2}
                value={customBrief.rsr_problem}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_problem: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="На какие данные нужно обратить больше всего внимания? Какую информацию нужно найти? *"
                fullWidth
                multiline
                rows={2}
                value={customBrief.rsr_data}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_data: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Как будут использованы результаты в отчете? *"
                fullWidth
                multiline
                rows={2}
                value={customBrief.rsr_usage}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_usage: e.target.value })}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Предпросмотр шаблона */}
      {selectedTemplateId && templateDesign && (
        <Box sx={{ border: '1px solid #ccc', p: 2, mt: 3, borderRadius: 1 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Предпросмотр шаблона: {templateName}
          </Typography>
          <pre style={{ background: '#f5f5f5', padding: '1rem', overflowX: 'auto' }}>
            {JSON.stringify(templateDesign, null, 2)}
          </pre>
        </Box>
      )}
    </Box>
  );
};

export default WizardStep3Design;
