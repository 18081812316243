import React, { useState, useContext } from 'react';
import { Box, Typography, Button, CircularProgress, Alert } from '@mui/material';
import { confirmTemplateDesign } from '../../services/api';
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

/**
 * Шаг 4: подтверждение дизайна (если выбрали шаблон).
 * 
 * Props:
 *  selectedProductId: string
 *  selectedTemplateId: string
 *  templateDesign: { modules: [...] } | null
 */
const WizardStep4Confirm = ({
  selectedProductId,
  selectedTemplateId,
  templateDesign
}) => {
  const navigate = useNavigate();
  const { setNotification } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleConfirmTemplateDesign = async () => {
    if (!selectedTemplateId || !templateDesign) {
      setNotification({ type: 'error', text: 'Не выбраны шаблон или дизайн' });
      return;
    }
    setLoading(true);
    try {
      const modules_data = templateDesign.modules || [];
      const payload = {
        template_id: selectedTemplateId,
        prod_id: selectedProductId,
        modules_data
      };
      await confirmTemplateDesign(payload);
      setNotification({ type: 'success', text: 'Исследование создано из шаблона!' });
      navigate('/');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setErrors(err.response.data.result);
        setNotification({ type: 'error', text: 'Ошибка при подтверждении дизайна' });
      } else {
        setNotification({ type: 'error', text: 'Неизвестная ошибка' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 4: Подтверждение дизайна</Typography>
      {selectedTemplateId && templateDesign ? (
        <Box sx={{ border: '1px solid #ccc', p: 2, mt: 2, borderRadius: 1 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Подтвердите дизайн исследования из выбранного шаблона:
          </Typography>
          <pre style={{ background: '#f5f5f5', padding: '1rem', overflowX: 'auto' }}>
            {JSON.stringify(templateDesign, null, 2)}
          </pre>
          {errors && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="error">Ошибки в брифе:</Typography>
              {Object.entries(errors).map(([field, errorObj]) => (
                !errorObj.is_relevant && (
                  <Alert severity="error" key={field} sx={{ mt: 1 }}>
                    {field}: {errorObj.reason}
                  </Alert>
                )
              ))}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleConfirmTemplateDesign} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Подтвердить'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">
            Для кастомного исследования этот шаг не требуется. Нажмите «Завершить» внизу.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default WizardStep4Confirm;
