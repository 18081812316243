// frontend/src/pages/ResearchWizard.js

import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

// Импорт шагов
import WizardStep1Organization from '../components/wizard/WizardStep1Organization';
import WizardStep2Product from '../components/wizard/WizardStep2Product';
import WizardStep3Design from '../components/wizard/WizardStep3Design';
import WizardStep4Confirm from '../components/wizard/WizardStep4Confirm';

import {
  getMyOrgs,
  getResearchTemplates,
  createResearch,
  createResearchFromTemplate,
  confirmTemplateDesign,
  createOrg,
  createProduct,
  getProducts
} from '../services/api';

/** Названия шагов */
const steps = [
  'Организация',
  'Продукт',
  'Дизайн исследования',
  'Подтверждение'
];

const ResearchWizard = () => {
  const navigate = useNavigate();
  const { user, setNotification } = useContext(AppContext);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  /** Данные для Шаг 1: организации */
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [isNewOrg, setIsNewOrg] = useState(false);
  const [newOrgData, setNewOrgData] = useState({
    company_name: '',
    company_inn: '',
    industry: '',
    position: 'Директор'
  });

  /** Данные для Шаг 2: продукты */
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [newProductData, setNewProductData] = useState({
    product_name: '',
    about_product: '',
    website: ''
  });

  /** Данные для Шаг 3: дизайн (шаблоны / бриф) */
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [customBrief, setCustomBrief] = useState({
    rsr_objectives: '',
    rsr_problem: '',
    rsr_data: '',
    rsr_usage: ''
  });
  const [templateDesign, setTemplateDesign] = useState(null);
  const [templateName, setTemplateName] = useState('');

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    loadOrgs();
    loadTemplates();
  }, []);

  // Новый useEffect для загрузки продуктов при изменении selectedOrgId
  useEffect(() => {
    if (selectedOrgId) {
      loadProducts(selectedOrgId);
    } else {
      setProducts([]); // Очистить список продуктов, если организация не выбрана
      setSelectedProductId('');
    }
  }, [selectedOrgId]);

  const loadOrgs = async () => {
    try {
      const resp = await getMyOrgs();
      setOrgs(resp.data);
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при загрузке организаций' });
    }
  };

  const loadTemplates = async () => {
    try {
      const resp = await getResearchTemplates();
      setTemplates(resp.data);
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при загрузке шаблонов' });
    }
  };

  const loadProducts = async (orgId) => {
    try {
      const resp = await getProducts(orgId);
      setProducts(resp.data);
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при загрузке продуктов' });
    }
  };

  // Функция для обновления списка организаций из дочернего компонента
  const handleOrgUpdated = async () => {
    await loadOrgs();
    // Если выбранная организация была обновлена, можно обновить её данные локально
    const updatedOrg = orgs.find(org => org.org_id === selectedOrgId);
    if (updatedOrg) {
      setSelectedOrgId(updatedOrg.org_id);
      loadProducts(updatedOrg.org_id);
    }
  };

  // Переход на следующий шаг
  const handleNext = async () => {
    // Валидация и обработка текущего шага
    if (activeStep === 0) {
      if (isNewOrg) {
        // Валидация новых данных организации
        const { company_name, industry } = newOrgData;
        if (!company_name.trim() || !industry.trim()) {
          setNotification({ type: 'error', text: 'Название и отрасль обязательны' });
          return;
        }

        // Создание новой организации
        setLoading(true);
        try {
          const resp = await createOrg(newOrgData);
          setNotification({ type: 'success', text: 'Организация создана!' });
          setSelectedOrgId(resp.data.org_id);
          setIsNewOrg(false);
          setNewOrgData({
            company_name: '',
            company_inn: '',
            industry: '',
            position: 'Директор'
          });
          await loadOrgs();
          await loadProducts(resp.data.org_id); // Загрузка продуктов для новой организации
        } catch (err) {
          console.error(err);
          setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при создании организации' });
          setLoading(false);
          return;
        }
        setLoading(false);
      } else {
        // Проверка выбора существующей организации
        if (!selectedOrgId) {
          setNotification({ type: 'error', text: 'Пожалуйста, выберите организацию или создайте новую' });
          return;
        }
      }
    }

    if (activeStep === 1) {
      if (isNewProduct) {
        // Валидация новых данных продукта
        const { product_name, about_product } = newProductData;
        if (!product_name.trim()) {
          setNotification({ type: 'error', text: 'Название продукта обязательно и не может состоять только из пробелов.' });
          return;
        }
        if (!about_product.trim()) {
          setNotification({ type: 'error', text: 'Описание продукта обязательно.' });
          return;
        }

        // Создание нового продукта
        setLoading(true);
        try {
          const payload = {
            org_id: selectedOrgId,
            product_name: newProductData.product_name,
            about_product: newProductData.about_product,
            website: newProductData.website
          };
          const resp = await createProduct(payload);
          setNotification({ type: 'success', text: 'Продукт создан!' });
          setSelectedProductId(resp.data.product_id);
          setIsNewProduct(false);
          setNewProductData({
            product_name: '',
            about_product: '',
            website: ''
          });
          await loadProducts(selectedOrgId); // Обновить список продуктов
        } catch (err) {
          console.error(err);
          setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при создании продукта' });
          setLoading(false);
          return;
        }
        setLoading(false);
      } else {
        // Проверка выбора существующего продукта
        if (!selectedProductId) {
          setNotification({ type: 'error', text: 'Пожалуйста, выберите продукт или создайте новый' });
          return;
        }
      }
    }

    if (activeStep === 2) {
      if (!selectedTemplateId) {
        // Кастомный бриф: проверка заполнения обязательных полей
        const { rsr_objectives, rsr_problem, rsr_data, rsr_usage } = customBrief;
        if (!rsr_objectives.trim() || !rsr_problem.trim() || !rsr_data.trim() || !rsr_usage.trim()) {
          setNotification({ type: 'error', text: 'Заполните все обязательные поля брифа' });
          return;
        }

        // Создание кастомного исследования
        setLoading(true);
        try {
          const payload = {
            prod_id: selectedProductId,
            rsr_objectives,
            rsr_problem,
            rsr_data,
            rsr_usage
          };
          const resp = await createResearch(payload);
          setNotification({ type: 'success', text: 'Исследование создано со статусом "Черновик заявки". Отправлено на проверку.' });
          setActiveStep((prev) => prev + 1);
        } catch (err) {
          console.error(err);
          setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при создании исследования' });
          setLoading(false);
          return;
        }
        setLoading(false);
      } else {
        // Шаблонный бриф: проверка загрузки дизайна
        if (!templateDesign) {
          setNotification({ type: 'error', text: 'Сначала загрузите дизайн шаблона' });
          return;
        }
      }
    }

    if (activeStep === 3) {
      if (selectedTemplateId && templateDesign) {
        // Подтверждение шаблонного дизайна
        setLoading(true);
        try {
          const payload = {
            template_id: selectedTemplateId,
            prod_id: selectedProductId,
            modules_data: templateDesign.modules || []
          };
          await confirmTemplateDesign(payload);
          setNotification({ type: 'success', text: 'Исследование создано из шаблона!' });
          navigate('/');
        } catch (err) {
          console.error(err);
          setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при подтверждении дизайна' });
        } finally {
          setLoading(false);
        }
      } else {
        // Для кастомного исследования просто завершить
        setNotification({ type: 'success', text: 'Исследование успешно создано!' });
        navigate('/');
      }
      return;
    }

    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  if (!user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <WizardStep1Organization
            orgs={orgs}
            selectedOrgId={selectedOrgId}
            setSelectedOrgId={setSelectedOrgId}
            isNewOrg={isNewOrg}
            setIsNewOrg={setIsNewOrg}
            newOrgData={newOrgData}
            setNewOrgData={setNewOrgData}
            onOrgUpdated={handleOrgUpdated} // Передаём функцию обновления
          />
        );
      case 1:
        return (
          <WizardStep2Product
            selectedOrgId={selectedOrgId}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            products={products}
            setProducts={setProducts}
            isNewProduct={isNewProduct}
            setIsNewProduct={setIsNewProduct}
            newProductData={newProductData}
            setNewProductData={setNewProductData}
          />
        );
      case 2:
        return (
          <WizardStep3Design
            selectedProductId={selectedProductId}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            customBrief={customBrief}
            setCustomBrief={setCustomBrief}
            templateDesign={templateDesign}
            setTemplateDesign={setTemplateDesign}
            templateName={templateName}
            setTemplateName={setTemplateName}
            templates={templates}
          />
        );
      case 3:
        return (
          <WizardStep4Confirm
            selectedProductId={selectedProductId}
            selectedTemplateId={selectedTemplateId}
            templateDesign={templateDesign}
          />
        );
      default:
        return <Typography>Неизвестный шаг</Typography>;
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Мастер создания исследования
      </Typography>

      <Grid container spacing={2}>
        {/* Stepper слева на больших экранах */}
        <Grid item xs={12} md={3}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, i) => (
              <Step key={i}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {/* Контент шага справа */}
        <Grid item xs={12} md={9}>
          {activeStep === steps.length ? (
            <Box>
              <Typography variant="h5">Готово!</Typography>
              <Button onClick={() => navigate('/')} variant="contained" sx={{ mt: 2 }}>
                На главную
              </Button>
            </Box>
          ) : (
            <>
              {renderStep()}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button disabled={activeStep === 0 || loading} onClick={handleBack}>
                  Назад
                </Button>
                <Button variant="contained" onClick={handleNext} disabled={loading}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : (activeStep === steps.length - 1 ? 'Завершить' : 'Далее')}
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResearchWizard;
