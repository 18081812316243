// frontend/src/services/api.js

import axios from 'axios';

/**
 * Создаём экземпляр axios с базовым URL и нужными заголовками
 */
const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// ---------------------------
// Добавление токена в заголовки запросов
// ---------------------------
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// ---------------------------
// Глобальная обработка ответов
// ---------------------------
API.interceptors.response.use(
  (response) => response,
  (error) => {
    // Если сервер вернул 401 - считаем, что токен недействителен
    if (error.response?.status === 401) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// ===================================
// Методы для взаимодействия с API пользователей
// ===================================

/**
 * Авторизация через Telegram (обычный)
 * @param {object} userData - Данные пользователя
 */
export const telegramAuth = (userData) =>
  API.post('users/telegram_auth/', null, { params: userData });

/**
 * Авторизация через Telegram WebApp
 * @param {string} initData - Инициализационные данные WebApp
 */
export const telegramWebAppAuth = (initData) =>
  API.post('users/telegram_webapp_auth/', { init_data: initData });

/**
 * Получение «дашборда» пользователя (общие данные)
 */
export const getDashboard = () =>
  API.get('users/dashboard/');

/**
 * Обновление настроек пользователя (тема и т.д.)
 * @param {object} settings - Новые настройки
 */
export const updateSettings = (settings) =>
  API.post('users/settings/', settings);

/**
 * Запрос на проверку / отправку телефона
 */
export const verifyPhone = () =>
  API.post('users/verify_phone/');

/**
 * Обновление телефона
 * @param {string} phoneNumber - Новый номер телефона
 */
export const updateUserPhone = (phoneNumber) =>
  API.post('users/update_phone/', { phone_number: phoneNumber });

/**
 * Валидация «уникального ключа» (если есть логика инвайтов)
 * @param {object} userData - Данные пользователя
 * @param {string} uniqueKey - Уникальный ключ
 */
export const validateKey = (userData, uniqueKey) =>
  API.post('users/validate_key/', {
    tg_id: userData.tg_id,
    first_name: userData.first_name,
    last_name: userData.last_name,
    username: userData.username,
    photo_url: userData.photo_url,
    unique_key: uniqueKey
  });

// ===================================
// Методы для логики организаций / продуктов / исследований
// ===================================

// ---------- Организации ----------
/**
 * Получить список организаций, в которых состоит текущий пользователь
 */
export const getMyOrgs = () =>
  API.get('researches/my_orgs');

/**
 * Создать новую организацию
 * @param {object} payload - Данные новой организации
 * payload = {
 *   company_name: string,
 *   industry: string,
 *   company_inn?: string,
 *   position?: string
 * }
 */
export const createOrg = (payload) =>
  API.post('researches/create_org', payload);

/**
 * Обновить организацию
 * @param {string} orgId - ID организации
 * @param {object} data - Данные для обновления
 * data = {
 *   company_name?: string,
 *   industry?: string,
 *   company_inn?: string,
 *   position?: string
 * }
 */
export const updateOrg = (orgId, data) =>
  API.put(`researches/update_org/${orgId}`, data);

// ---------- Продукты ----------
/**
 * Получить список продуктов в организации orgId
 * @param {string} orgId - ID организации
 */
export const getProducts = (orgId) =>
  API.get(`researches/get_products/${orgId}`);

/**
 * Создать продукт в указанной организации
 * @param {object} payload - Данные нового продукта
 * payload = {
 *   org_id: string,
 *   product_name?: string,
 *   about_product: string,
 *   website?: string
 * }
 */
export const createProduct = (payload) =>
  API.post('researches/create_product', payload);

/**
 * Обновить продукт
 * @param {string} productId - ID продукта
 * @param {object} data - Данные для обновления
 * data = {
 *   product_name?: string,
 *   about_product?: string,
 *   website?: string
 * }
 */
export const updateProduct = (productId, data) =>
  API.put(`researches/update_product/${productId}`, data);

// ---------- Шаблоны ----------
/**
 * Получить список доступных шаблонов
 */
export const getResearchTemplates = () =>
  API.get('researches/templates');

// ---------- Исследования ----------
/**
 * Получить список исследований пользователя
 */
export const getMyResearches = () =>
  API.get('researches/my_researches');

/**
 * Создать кастомное исследование (бриф)
 * @param {object} payload - Данные исследования
 * payload = {
 *   prod_id: string,
 *   rsr_objectives: string,
 *   rsr_problem: string,
 *   rsr_data: string,
 *   rsr_usage: string
 * }
 */
export const createResearch = (payload) =>
  API.post('researches/create_research', payload);

/**
 * Запросить дизайн шаблона (ещё без создания исследования)
 * @param {string} templateId - ID шаблона
 * @param {string} prodId - ID продукта
 */
export const createResearchFromTemplate = (templateId, prodId) =>
  API.post('researches/create_research_from_template', {
    template_id: templateId,
    prod_id: prodId
  });

/**
 * Подтвердить шаблонный дизайн (создаёт запись в rsr_list + rsr_design)
 * @param {object} payload - Данные подтверждения дизайна
 * payload = {
 *   template_id: string,
 *   prod_id: string,
 *   modules_data: Array<ModuleDesign>
 * }
 */
export const confirmTemplateDesign = (payload) =>
  API.post('researches/confirm_template_design', payload);

/**
 * Получить детали исследования (бриф, статус, модули и т.д.)
 * @param {string} researchId - ID исследования
 */
export const getResearchById = (researchId) =>
  API.get(`researches/get_research/${researchId}`);

/**
 * Обновить дизайн исследования (кастом) при статусе 'design', если нужно
 * @param {string} researchId - ID исследования
 * @param {Array<ModuleDesign>} updatedDesign - Обновлённые данные дизайна
 */
export const updateResearchDesign = (researchId, updatedDesign) =>
  API.post(`researches/update_design/${researchId}`, updatedDesign);

export default API;
