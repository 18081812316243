import React, { useEffect, useContext, useState } from 'react';
import {
  Typography,
  Container,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { getMyResearches } from '../services/api';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, fetchUserData } = useContext(AppContext);

  const [researches, setResearches] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const load = async () => {
      if (user) {
        setLoading(true);
        try {
          const resp = await getMyResearches();
          setResearches(resp.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };
    load();
  }, [user]);

  if (!user || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {/* Начать новое исследование */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'primary.main',
              color: '#ffffff',
              boxShadow: 4,
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: 5,
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Начать новое исследование
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/research-wizard')}
              sx={{
                mt: 1,
                backgroundColor: 'secondary.main',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#17d1a4',
                },
              }}
            >
              Начать
            </Button>
          </Card>
        </Grid>

        {/* Список исследований */}
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>
            Ваши исследования
          </Typography>
          {researches.length === 0 && (
            <Typography>У вас пока нет исследований.</Typography>
          )}
          <Grid container spacing={1}>
            {researches.map((rsr) => (
              <Grid item xs={12} sm={6} key={rsr.research_id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 3,
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 4,
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {rsr.rsr_name || 'Без названия'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Статус: {rsr.status}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ mt: 'auto' }}>
                    <Button
                      size="small"
                      onClick={() => navigate(`/research/${rsr.research_id}`)}
                      color="primary"
                    >
                      Подробнее
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
