// frontend/src/components/wizard/WizardStep4Confirm.js

import React, { useState, useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { confirmTemplateDesign } from '../../services/api';
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

/**
 * Шаг 4: подтверждение дизайна (если выбрали шаблон).
 * 
 * Props:
 *  selectedProductId: string
 *  selectedTemplateId: string
 *  templateDesign: { modules: [...] } | null
 */
const WizardStep4Confirm = ({
  selectedProductId,
  selectedTemplateId,
  templateDesign
}) => {
  const navigate = useNavigate();
  const { setNotification } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const handleConfirmTemplateDesign = async () => {
    if (!selectedTemplateId || !templateDesign) {
      setNotification({ type: 'error', text: 'Не выбраны шаблон или дизайн' });
      return;
    }
    setLoading(true);
    try {
      const modules_data = templateDesign.modules || [];
      const payload = {
        template_id: selectedTemplateId,
        prod_id: selectedProductId,
        modules_data
      };
      const resp = await confirmTemplateDesign(payload);
      setNotification({ type: 'success', text: 'Исследование создано из шаблона!' });
      navigate('/');
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при подтверждении дизайна' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 4: Подтверждение дизайна</Typography>
      {selectedTemplateId && templateDesign ? (
        <Box sx={{ border: '1px solid #ccc', p: 2, mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Подтвердить дизайн шаблона?
          </Typography>
          <pre style={{ background: '#f5f5f5', padding: '1rem' }}>
            {JSON.stringify(templateDesign, null, 2)}
          </pre>
          <Button variant="contained" onClick={handleConfirmTemplateDesign} disabled={loading}>
            {loading ? 'Сохраняем...' : 'Подтвердить'}
          </Button>
        </Box>
      ) : (
        <Typography sx={{ mt: 2 }}>
          Для кастомного исследования этот шаг не требуется. Нажмите «Завершить» внизу.
        </Typography>
      )}
    </Box>
  );
};

export default WizardStep4Confirm;
