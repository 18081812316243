// frontend/src/components/Layout.js

import React, { useContext, useState } from 'react';
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppContext } from '../context/AppContext';
import Logo from './Logo';
import LogoMin from './LogoMin'; // Импорт минимизированного логотипа
import { useNavigate } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import EnergeticBlocksBackground from './EnergeticBlocksBackground'; // Импорт фона

const Layout = ({ children }) => {
  const { user, isTelegramWebApp, setNotification } = useContext(AppContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    navigate('/settings');
    handleCloseMenu();
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setNotification({ type: 'success', text: 'Вы успешно вышли из аккаунта.' });
    navigate('/login');
    handleCloseMenu();
  };

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      {/* Фоновый компонент для защищённых страниц */}
      <EnergeticBlocksBackground />

      {/* Верхняя панель */}
      {!isFullScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
          }}
        >
          {/* Логотип */}
          <Box
            onClick={() => navigate('/')}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isSmallScreen ? <LogoMin height={30} /> : <Logo height={30} />}
          </Box>

          {/* Навигация и аватар */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography
              variant="body1"
              sx={{
                cursor: 'pointer',
                fontSize: 16,
                color: 'white',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
              onClick={() => navigate('/')}
            >
              Исследования
            </Typography>
            <Typography
              variant="body1"
              sx={{
                cursor: 'pointer',
                fontSize: 16,
                color: 'white',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
              onClick={() => navigate('/wiki')}
            >
              База знаний
            </Typography>

            {user && (
              <IconButton onClick={handleAvatarClick} sx={{ padding: 0 }}>
                <Avatar
                  src={user.photo_url || '/bot-icon.png'}
                  alt={user.first_name}
                  sx={{
                    cursor: 'pointer',
                    width: 48,
                    height: 48,
                    border: '2px solid',
                    borderColor: 'secondary.main',
                  }}
                />
              </IconButton>
            )}
          </Box>

          {/* Меню аватарки */}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                bgcolor: 'background.paper',
                animation: 'fadeIn 0.3s ease-out',
              },
              '@keyframes fadeIn': {
                from: {
                  opacity: 0,
                  transform: 'scale(0.95)',
                },
                to: {
                  opacity: 1,
                  transform: 'scale(1)',
                },
              },
            }}
          >
            <MenuItem
              onClick={handleSettings}
              sx={{
                '&:hover': {
                  bgcolor: 'secondary.light',
                },
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Настройки
            </MenuItem>
            {!isTelegramWebApp && (
              <MenuItem
                onClick={handleLogout}
                sx={{
                  '&:hover': {
                    bgcolor: 'secondary.light',
                  },
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Выйти
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}

      {/* Основной контент */}
      <Box
        sx={{
          flex: 1,
          padding: isFullScreen ? '16px 0 0' : 4,
          paddingTop: 5, // Одинаковый верхний отступ для контента в обоих режимах
          margin: isFullScreen ? 0 : 2,
          bgcolor: 'background.paper',
          borderRadius: isFullScreen ? 0 : 4,
          boxShadow: isFullScreen ? 'none' : 3,
          overflow: 'auto',
          position: 'relative',
          transition: 'all 0.3s ease',
        }}
      >
        <IconButton
          onClick={toggleFullScreen}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 1,
          }}
        >
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>

        {/* Контент */}
        <Box sx={{ marginTop: 4 }}>{children}</Box>
      </Box>

      {/* Футер */}
      {!isFullScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            bgcolor: 'transparent',
            color: '#ffffff',
            fontSize: 14,
            padding: 2,
          }}
        >
          AI.Инсайт. 2024 — ver. alpha 0.4.7
        </Box>
      )}
    </Box>
  );
};

export default Layout;
