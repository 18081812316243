// frontend/src/pages/TelegramWebAppAuth.js

import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Paper, Divider, Link, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { telegramWebAppAuth } from '../services/api';
import { AppContext } from '../context/AppContext';
import EnergeticWavesBackground from '../components/EnergeticWavesBackground'; // Используем свой фон
import Logo from '../components/Logo';
import messages from './messages'; // Предполагается, что у вас есть массив сообщений для динамичного текста
import { styled, keyframes } from '@mui/material/styles';
import UniqueKeyForm from '../components/UniqueKeyForm'; // Импортируем UniqueKeyForm

// Определяем анимацию для мигающего курсора
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Создаём стилизованный компонент для курсора
const BlinkingCursor = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '2px',
  height: '1em',
  backgroundColor: theme.palette.primary.main,
  marginLeft: '4px',
  animation: `${blink} 1s step-start infinite`,
}));

const TelegramWebAppAuth = () => {
  const navigate = useNavigate();
  const { isTelegramWebApp, toggleTheme, fetchUserData, setNotification } = useContext(AppContext);

  // Состояния для динамичного текста
  const [displayedText, setDisplayedText] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  // Состояния для обработки уникального ключа и статуса пользователя
  const [isNewUser, setIsNewUser] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isInactive, setIsInactive] = useState(false);
  const [isPendingApproval, setIsPendingApproval] = useState(false); // Новое состояние

  useEffect(() => {
    // Обработка динамичного текста с мигающим курсором
    if (charIndex < messages[currentMessageIndex].length) {
      const typingTimeout = setTimeout(() => {
        setDisplayedText(prev => prev + messages[currentMessageIndex][charIndex]);
        setCharIndex(prev => prev + 1);
      }, 100);

      return () => clearTimeout(typingTimeout);
    } else {
      const pauseTimeout = setTimeout(() => {
        setDisplayedText('');
        setCharIndex(0);
        setCurrentMessageIndex(prev => (prev + 1) % messages.length);
      }, 2000);

      return () => clearTimeout(pauseTimeout);
    }
  }, [charIndex, currentMessageIndex]);

  useEffect(() => {
    if (!isTelegramWebApp) {
      navigate('/login');
      return;
    }
  
    const initData = window.Telegram.WebApp.initData;
  
    if (initData) {
      telegramWebAppAuth(initData.toString())
        .then(response => {
          const { access_token, status, message, user_data, theme } = response.data;
  
          if (status === 'active') {
            localStorage.setItem('access_token', access_token);
            toggleTheme(theme === 'auto' ? 'auto' : theme);
            fetchUserData();
            setNotification({ type: 'success', text: 'Успешная авторизация!' });
            navigate('/');
          } else if (status === 'inactive') {
            setIsInactive(true);
            setNotification({ type: 'warning', text: message });
          } else if (status === 'new') {
            setIsNewUser(true);
            setUserData(user_data);
          }
        })
        .catch(error => {
          console.error('Telegram Web App Auth Error:', error);
          setNotification({ type: 'error', text: 'Ошибка авторизации через Telegram.' });
          navigate('/login');
        });
    } else {
      navigate('/login');
    }
  }, [navigate, isTelegramWebApp, toggleTheme, fetchUserData, setNotification]);

  const handleKeySuccess = () => {
    setIsNewUser(false);
    setIsPendingApproval(true); // Устанавливаем состояние ожидания одобрения
    setNotification({ type: 'info', text: 'Ваша учетная запись создана и ожидает одобрения администратора.' });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      position="relative"
      sx={{ textAlign: 'center', px: 2 }}
    >
      <EnergeticWavesBackground />

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: { xs: '100%', sm: 500, md: 900 },
        }}
      >
        <Paper
          elevation={6}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 2, md: 0 },
            borderRadius: '16px',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Box
            flex={1}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              pr: { md: 4 },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                lineHeight: 1.2,
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              }}
            >
              Инсайты из океанов данных о конкурентах и рынке
            </Typography>
            <Box
              sx={{
                minHeight: '4rem',
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                alignItems: 'flex-start',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.main',
                  fontSize: { xs: '1.25rem', sm: '1.25rem' },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {displayedText}
                <BlinkingCursor />
              </Typography>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: 'none', md: 'block' },
              mx: 3,
            }}
          />
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              display: { xs: 'block', md: 'none' },
              my: 2,
              width: '80%',
            }}
          />

          <Box
            flex={1}
            sx={{
              textAlign: 'center',
            }}
          >
            {/* Нет виджета Telegram Login для WebApp, так как вход осуществляется через initData */}

            {/* Отображение формы ввода уникального ключа для новых пользователей */}
            {isNewUser && userData && (
              <UniqueKeyForm 
                userData={userData} 
                onSuccess={handleKeySuccess} 
                setNotification={setNotification} 
              />
            )}

            {/* Отображение сообщения о статусе учетной записи после успешной отправки ключа */}
            {isPendingApproval && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Ваша учетная запись не получила или не имеет доступа в систему. Дождитесь одобрения администратора.
              </Alert>
            )}

            {/* Отображение сообщения для неактивных пользователей */}
            {isInactive && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                Ваша учетная запись не получила или не имеет доступа в систему. Дождитесь одобрения администратора.
              </Alert>
            )}

            <Typography
              variant="caption"
              sx={{
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
              }}
            >
              Авторизуясь, вы соглашаетесь с нашими{' '}
              <Link href="/terms-of-use" underline="hover">
                Пользовательским соглашением
              </Link>
              ,{' '}
              <Link href="/privacy-policy" underline="hover">
                Политикой конфиденциальности
              </Link>{' '}
              и{' '}
              <Link href="/refund-policy" underline="hover">
                Политикой оплаты и возврата
              </Link>.
            </Typography>
          </Box>
        </Paper>

        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'black',
            color: 'white',
            padding: '20px 0px',
            textAlign: 'center',
            borderRadius: '0 0 16px 16px',
            mt: '-10px',
            zIndex: 1,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              lineHeight: 1.6,
            }}
          >
            ❗ Платформа находится в закрытом pre-alpha тесте.
            <br />
            ❗ Регистрация потребует уникальный ключ. Если у вас его нет - зарегистрироваться не получится 😢
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <Logo width="150px" />
      </Box>
    </Box>
  );
};

export default TelegramWebAppAuth;
