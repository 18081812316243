// frontend/src/components/UniqueKeyForm.js

import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { validateKey } from '../services/api';

const UniqueKeyForm = ({ userData, onSuccess, setNotification }) => {
  const [uniqueKey, setUniqueKey] = useState('');
  const [keyError, setKeyError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKeyError('');
    setIsSubmitting(true);

    if (!uniqueKey.trim()) {
      setKeyError('Пожалуйста, введите уникальный ключ.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await validateKey(userData, uniqueKey.trim());
      const { status, message } = response.data;

      if (status === 'pending') {
        setNotification({ type: 'info', text: message });
        onSuccess(); // Вызываем функцию-обработчик после успешной валидации
      }
    } catch (error) {
      console.error('Validate Key Error:', error);
      if (error.response && error.response.data && error.response.data.detail) {
        setKeyError(error.response.data.detail);
      } else {
        setKeyError('Произошла ошибка при валидации ключа.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Введите уникальный ключ для регистрации
      </Typography>
      <TextField
        label="Уникальный ключ"
        variant="outlined"
        fullWidth
        value={uniqueKey}
        onChange={(e) => setUniqueKey(e.target.value)}
        error={Boolean(keyError)}
        helperText={keyError}
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
        {isSubmitting ? 'Проверка...' : 'Отправить'}
      </Button>
    </Box>
  );
};

export default UniqueKeyForm;
