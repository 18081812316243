import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { updateSettings, verifyPhone } from '../services/api';
import { AppContext } from '../context/AppContext';
import GeneralSettings from '../components/GeneralSettings';

const Settings = () => {
  const { isTelegramWebApp, toggleTheme, user, fetchUserData, setNotification } = useContext(AppContext);
  
  const [formData, setFormData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [showBotLink, setShowBotLink] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState('general');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (user) {
      setFormData({
        theme: user.theme || 'light',
        email: user.email || '',
        phone: user.phone || '',
        is_phone_verified: user.is_phone_verified || false,
      });
      setHasChanges(false);
    }
  }, [user]);

  if (!user || !formData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleInputChange = (field, value) => {
    if (field === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Некорректный email адрес');
      } else {
        setEmailError('');
      }
    }

    setFormData((prev) => {
      const updated = { ...prev, [field]: value };
      const changed =
        updated.theme !== (user.theme || 'auto') ||
        updated.email !== (user.email || '') ||
        updated.phone !== (user.phone || '');
      setHasChanges(changed);
      return updated;
    });
  };

  const handleVerifyPhone = async () => {
    try {
      const response = await verifyPhone();
      const { message: msg, bot_link } = response.data;
      setVerificationMessage(bot_link ? `${msg}. Вы можете перейти в ` : msg);
      setShowBotLink(!!bot_link);
    } catch (error) {
      console.error('Verify Phone Error:', error);
      setVerificationMessage('Ошибка при отправке сообщения. Попробуйте позже.');
      setShowBotLink(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      setNotification({ type: 'error', text: 'Пожалуйста, исправьте ошибки в форме.' });
      return;
    }
    try {
      const response = await updateSettings(formData);
      setNotification({ type: 'success', text: 'Настройки успешно обновлены.' });
      toggleTheme(response.data.theme === 'auto' ? 'auto' : response.data.theme);
      fetchUserData();
      setHasChanges(false);
    } catch (error) {
      console.error('Update Settings Error:', error);
      setNotification({ type: 'error', text: 'Ошибка при обновлении настроек.' });
    }
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedBlock === 'general'}
                onClick={() => setSelectedBlock('general')}
              >
                <ListItemText primary="Общие настройки" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={8}>
          {selectedBlock === 'general' && (
            <GeneralSettings
              formData={formData}
              handleInputChange={handleInputChange}
              emailError={emailError}
              verificationMessage={verificationMessage}
              showBotLink={showBotLink}
              isTelegramWebApp={isTelegramWebApp}
              handleVerifyPhone={handleVerifyPhone}
            />
          )}
          {hasChanges && selectedBlock === 'general' && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  minWidth: 200,
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#1BF2B5',
                  },
                }}
              >
                Сохранить
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
