// frontend/src/components/GeneralSettings.js

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
} from '@mui/material';

const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,4})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phone;
};

const GeneralSettings = ({
  formData,
  handleInputChange,
  emailError,
  verificationMessage,
  showBotLink,
  isTelegramWebApp,
  handleVerifyPhone,
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Общие настройки
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1">
            Номер телефона: {formData.phone ? formatPhoneNumber(formData.phone) : 'Не указан'}
          </Typography>
          <Typography
            variant="body2"
            color={formData.is_phone_verified ? 'success.main' : 'error.main'}
            sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
          >
            {formData.is_phone_verified ? (
              <>
                Подтвержден <span role="img" aria-label="verified" style={{ marginLeft: 4 }}>✅</span>
              </>
            ) : (
              <>
                Не подтвержден <span role="img" aria-label="not-verified" style={{ marginLeft: 4 }}>❌</span>
              </>
            )}
          </Typography>
          {!formData.is_phone_verified && (
            isTelegramWebApp ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.Telegram.WebApp.requestContact()}
                sx={{
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#1BF2B5',
                  },
                }}
              >
                Подтвердить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyPhone}
                sx={{ mt: 2 }}
              >
                Подтвердить
              </Button>
            )
          )}
          {verificationMessage && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {verificationMessage}
              {showBotLink && (
                <Link href="https://t.me/aiinsight_ru_bot" target="_blank" rel="noopener">
                  бота
                </Link>
              )}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            label="Электронная почта"
            placeholder="Введите email"
            variant="outlined"
            error={!!emailError}
            helperText={emailError}
          />
        </Box>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="theme-label">Тема приложения</InputLabel>
            <Select
              labelId="theme-label"
              value={formData.theme}
              label="Тема приложения"
              onChange={(e) => handleInputChange('theme', e.target.value)}
            >
              <MenuItem value="light">Светлая</MenuItem>
              <MenuItem value="dark">Тёмная</MenuItem>
              <MenuItem value="auto">Автоматическая</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralSettings;
