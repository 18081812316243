// frontend/src/components/wizard/WizardStep1Organization.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from '../../context/AppContext';
import { updateOrg as apiUpdateOrg } from '../../services/api';

// Предопределённый список должностей
const POSITIONS = [
  'Директор',
  'Менеджер',
  'Главный инженер',
  'Бухгалтер',
  'Специалист по маркетингу',
  'Менеджер по продажам',
  'HR-менеджер',
  // Добавьте другие должности по необходимости
];

/**
 * Шаг 1: выбор или создание организации с возможностью редактирования.
 * 
 * Props:
 *  orgs: array
 *  selectedOrgId, setSelectedOrgId
 *  isNewOrg, setIsNewOrg: флаг создания новой организации
 *  newOrgData, setNewOrgData: данные для новой организации
 *  onOrgUpdated: функция для обновления списка организаций в родительском компоненте
 */
const WizardStep1Organization = ({
  orgs,
  selectedOrgId,
  setSelectedOrgId,
  isNewOrg,
  setIsNewOrg,
  newOrgData,
  setNewOrgData,
  onOrgUpdated
}) => {
  const { setNotification } = useContext(AppContext);
  const [editOrgId, setEditOrgId] = useState(null);
  const [editOrgData, setEditOrgData] = useState({
    company_name: '',
    company_inn: '',
    industry: '',
    position: ''
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // Функция открытия диалогового окна редактирования организации
  const handleEditClick = (org) => {
    setEditOrgId(org.org_id);
    setEditOrgData({
      company_name: org.company_name,
      company_inn: org.company_inn || '',
      industry: org.industry,
      position: org.position || 'Директор'
    });
    setEditDialogOpen(true);
  };

  // Функция сохранения изменений организации
  const handleSaveEdit = async () => {
    // Валидация обязательных полей
    if (!editOrgData.company_name.trim() || !editOrgData.industry.trim()) {
      setNotification({ type: 'error', text: 'Название и отрасль обязательны' });
      return;
    }

    setEditLoading(true);
    try {
      const updatedOrg = await apiUpdateOrg(editOrgId, editOrgData);
      setNotification({ type: 'success', text: 'Организация обновлена!' });
      setEditDialogOpen(false);

      // Вызов функции для обновления списка организаций в родительском компоненте
      if (onOrgUpdated) {
        onOrgUpdated();
      }
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при обновлении организации' });
    } finally {
      setEditLoading(false);
    }
  };

  // Функция обработки клика по карточке организации
  const handleCardClick = (org_id) => {
    setSelectedOrgId(org_id);
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 1: Организация</Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Выбор существующей организации */}
        {!isNewOrg && (
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Выберите существующую организацию:</Typography>
            {orgs.length > 0 ? (
              orgs.map((org) => (
                <Card
                  key={org.org_id}
                  onClick={() => handleCardClick(org.org_id)}
                  variant="outlined"
                  sx={{
                    mb: 2,
                    cursor: 'pointer',
                    borderColor: org.org_id === selectedOrgId ? 'primary.main' : 'grey.300',
                    backgroundColor: org.org_id === selectedOrgId ? 'primary.main' : 'transparent',
                    color: org.org_id === selectedOrgId ? 'primary.contrastText' : 'text.primary',
                    position: 'relative',
                  }}
                >
                  {/* Кнопка редактирования в правом верхнем углу */}
                  <IconButton
                    onClick={(e) => { e.stopPropagation(); handleEditClick(org); }}
                    color={org.org_id === selectedOrgId ? 'primary.contrastText' : 'primary'}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  >
                    <EditIcon />
                  </IconButton>

                  <CardContent>
                    <Typography variant="h6">{org.company_name}</Typography>
                    <Typography color="inherit">{org.industry}</Typography>
                    <Typography color="inherit">ИНН: {org.company_inn || 'Не указано'}</Typography>
                    <Typography color="inherit">Должность: {org.position}</Typography>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>У вас пока нет организаций. Создайте новую.</Typography>
            )}
          </Grid>
        )}

        {/* Переключатель между выбором существующей организации и созданием новой */}
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Button
            variant={isNewOrg ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => {
              setIsNewOrg(!isNewOrg);
              if (isNewOrg) {
                // Очистка данных при отмене создания новой организации
                setNewOrgData({
                  company_name: '',
                  company_inn: '',
                  industry: '',
                  position: 'Директор'
                });
              }
            }}
            fullWidth
          >
            {isNewOrg ? 'Отмена создания организации' : 'Создать новую организацию'}
          </Button>
        </Grid>

        {/* Форма создания новой организации */}
        {isNewOrg && (
          <Grid item xs={12}>
            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Название организации *"
                    fullWidth
                    value={newOrgData.company_name}
                    onChange={(e) => setNewOrgData({ ...newOrgData, company_name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="ИНН (необязательно)"
                    fullWidth
                    value={newOrgData.company_inn}
                    onChange={(e) => setNewOrgData({ ...newOrgData, company_inn: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Отрасль *</InputLabel>
                    <Select
                      value={newOrgData.industry}
                      label="Отрасль *"
                      onChange={(e) => setNewOrgData({ ...newOrgData, industry: e.target.value })}
                    >
                      <MenuItem value="IT">IT</MenuItem>
                      <MenuItem value="Retail">Retail</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="Healthcare">Healthcare</MenuItem>
                      {/* Добавьте другие отрасли по необходимости */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Ваша должность *</InputLabel>
                    <Select
                      value={newOrgData.position}
                      label="Ваша должность *"
                      onChange={(e) => setNewOrgData({ ...newOrgData, position: e.target.value })}
                    >
                      {POSITIONS.map((position) => (
                        <MenuItem key={position} value={position}>
                          {position}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Диалог редактирования организации */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Редактирование организации</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Название организации *"
                fullWidth
                value={editOrgData.company_name}
                onChange={(e) => setEditOrgData({ ...editOrgData, company_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="ИНН (необязательно)"
                fullWidth
                value={editOrgData.company_inn}
                onChange={(e) => setEditOrgData({ ...editOrgData, company_inn: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Отрасль *</InputLabel>
                <Select
                  value={editOrgData.industry}
                  label="Отрасль *"
                  onChange={(e) => setEditOrgData({ ...editOrgData, industry: e.target.value })}
                >
                  <MenuItem value="IT">IT</MenuItem>
                  <MenuItem value="Retail">Retail</MenuItem>
                  <MenuItem value="Finance">Finance</MenuItem>
                  <MenuItem value="Healthcare">Healthcare</MenuItem>
                  {/* Добавьте другие отрасли по необходимости */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Ваша должность *</InputLabel>
                <Select
                  value={editOrgData.position}
                  label="Ваша должность *"
                  onChange={(e) => setEditOrgData({ ...editOrgData, position: e.target.value })}
                >
                  {POSITIONS.map((position) => (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Отмена</Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary" disabled={editLoading}>
            {editLoading ? 'Сохранение...' : 'Сохранить'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WizardStep1Organization;
