// frontend/src/pages/ResearchWizard.js
import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, Stepper, Step, StepLabel, Button, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

// Импорт шагов
import WizardStep1Organization from '../components/wizard/WizardStep1Organization';
import WizardStep2Product from '../components/wizard/WizardStep2Product';
import WizardStep3Design from '../components/wizard/WizardStep3Design';
import WizardStep4Confirm from '../components/wizard/WizardStep4Confirm';

import { getMyOrgs, getResearchTemplates, createResearch, createResearchFromTemplate, confirmTemplateDesign } from '../services/api';

/** Названия шагов */
const steps = [
  'Организация',
  'Продукт',
  'Выбор дизайна / Бриф',
  'Подтверждение дизайна'
];

const ResearchWizard = () => {
  const navigate = useNavigate();
  const { user, setNotification } = useContext(AppContext);
  
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  /** Данные для Шаг 1: организации */
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  
  /** Данные для Шаг 2: продукты */
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');

  /** Данные для Шаг 3: дизайн (шаблоны / бриф) */
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [customBrief, setCustomBrief] = useState({
    rsr_objectives: '',
    rsr_problem: '',
    rsr_data: '',
    rsr_usage: ''
  });
  const [templateDesign, setTemplateDesign] = useState(null);
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    loadOrgs();
    loadTemplates();
  }, []);

  const loadOrgs = async () => {
    try {
      const resp = await getMyOrgs();
      setOrgs(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  const loadTemplates = async () => {
    try {
      const resp = await getResearchTemplates();
      setTemplates(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Переход на следующий шаг
  const handleNext = async () => {
    // Валидация
    if (activeStep === 0) {
      // Должна быть выбрана org
      if (!selectedOrgId) {
        setNotification({ type: 'error', text: 'Пожалуйста, выберите или создайте организацию' });
        return;
      }
    }
    if (activeStep === 1) {
      // Должен быть выбран продукт
      if (!selectedProductId) {
        setNotification({ type: 'error', text: 'Пожалуйста, выберите или создайте продукт' });
        return;
      }
    }
    if (activeStep === 2) {
      // Если кастомный бриф
      if (!selectedTemplateId) {
        // Проверяем поля
        const { rsr_objectives, rsr_problem, rsr_data, rsr_usage } = customBrief;
        if (!rsr_objectives.trim() || !rsr_problem.trim() || !rsr_data.trim() || !rsr_usage.trim()) {
          setNotification({ type: 'error', text: 'Заполните все поля брифа' });
          return;
        }
        // Создаём исследование
        setLoading(true);
        try {
          const payload = {
            prod_id: selectedProductId,
            rsr_objectives,
            rsr_problem,
            rsr_data,
            rsr_usage
          };
          await createResearch(payload);
          setNotification({ type: 'success', text: 'Исследование создано со статусом pending' });
        } catch (err) {
          console.error(err);
          setNotification({ type: 'error', text: 'Ошибка при создании исследования' });
          setLoading(false);
          return;
        }
        setLoading(false);
      } else {
        // Шаблон: должен быть загружен templateDesign
        if (!templateDesign) {
          setNotification({ type: 'error', text: 'Сначала загрузите дизайн шаблона' });
          return;
        }
      }
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  if (!user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <WizardStep1Organization
            orgs={orgs}
            onCreateOrgSuccess={() => loadOrgs()}
            selectedOrgId={selectedOrgId}
            setSelectedOrgId={setSelectedOrgId}
          />
        );
      case 1:
        return (
          <WizardStep2Product
            selectedOrgId={selectedOrgId}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            products={products}
            setProducts={setProducts}
          />
        );
      case 2:
        return (
          <WizardStep3Design
            selectedProductId={selectedProductId}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            customBrief={customBrief}
            setCustomBrief={setCustomBrief}
            templateDesign={templateDesign}
            setTemplateDesign={setTemplateDesign}
            templateName={templateName}
            setTemplateName={setTemplateName}
            templates={templates}
          />
        );
      case 3:
        return (
          <WizardStep4Confirm
            selectedProductId={selectedProductId}
            selectedTemplateId={selectedTemplateId}
            templateDesign={templateDesign}
          />
        );
      default:
        return <Typography>Неизвестный шаг</Typography>;
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Мастер создания исследования
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label, i) => (
          <Step key={i}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Box>
          <Typography variant="h5">Готово!</Typography>
          <Button onClick={() => navigate('/')}>На главную</Button>
        </Box>
      ) : (
        <>
          {renderStep()}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Назад
            </Button>
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              {activeStep === steps.length - 1 ? 'Завершить' : 'Далее'}
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ResearchWizard;
