// frontend/src/components/wizard/WizardStep1Organization.js

import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { createOrg } from '../../services/api';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

/**
 * Шаг 1: выбор уже имеющейся организации ИЛИ создание новой.
 * 
 * Props:
 *  orgs: массив [{ org_id, company_name, industry, ... }]
 *  onCreateOrgSuccess: колбэк, когда успешно создали (чтобы перезагрузить список, если надо)
 *  selectedOrgId, setSelectedOrgId: управление выбранной организацией
 */
const WizardStep1Organization = ({
  orgs,
  onCreateOrgSuccess,
  selectedOrgId,
  setSelectedOrgId
}) => {
  const { setNotification } = useContext(AppContext);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [loading, setLoading] = useState(false);

  // Поля формы:
  const [orgData, setOrgData] = useState({
    company_name: '',
    company_inn: '',
    industry: '',
    position: 'Директор'
  });

  const handleCreateOrg = async () => {
    // Валидация
    if (!orgData.company_name.trim() || !orgData.industry.trim()) {
      setNotification({ type: 'error', text: 'Название и отрасль обязательны' });
      return;
    }
    setLoading(true);
    try {
      const resp = await createOrg(orgData);
      setNotification({ type: 'success', text: 'Организация создана!' });
      setSelectedOrgId(resp.data.org_id);
      onCreateOrgSuccess(); // перезагрузить список с бэка, если нужно
      setShowCreateForm(false);
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при создании организации' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 1: Организация</Typography>
      
      {/* Кнопка "Начать исследование для новой организации" */}
      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: 2, mb: 2 }}
        onClick={() => setShowCreateForm(!showCreateForm)}
      >
        {showCreateForm ? 'Отмена' : 'Начать исследование для новой организации'}
      </Button>

      {/* Если пользователь выбрал "Начать исследование для новой" — показываем форму */}
      {showCreateForm && (
        <Box sx={{ border: '1px solid #ccc', p: 2, mb: 2 }}>
          <TextField
            label="Название организации *"
            fullWidth
            sx={{ mb: 1 }}
            value={orgData.company_name}
            onChange={(e) => setOrgData({ ...orgData, company_name: e.target.value })}
          />
          <TextField
            label="ИНН (необязательно)"
            fullWidth
            sx={{ mb: 1 }}
            value={orgData.company_inn}
            onChange={(e) => setOrgData({ ...orgData, company_inn: e.target.value })}
          />
          <TextField
            label="Отрасль (например: IT, Retail...) *"
            fullWidth
            sx={{ mb: 1 }}
            value={orgData.industry}
            onChange={(e) => setOrgData({ ...orgData, industry: e.target.value })}
          />
          <TextField
            label="Ваша должность"
            fullWidth
            sx={{ mb: 2 }}
            value={orgData.position}
            onChange={(e) => setOrgData({ ...orgData, position: e.target.value })}
          />
          <Button variant="outlined" onClick={handleCreateOrg} disabled={loading}>
            {loading ? 'Создаём...' : 'Сохранить организацию'}
          </Button>
        </Box>
      )}

      {/* Список уже имеющихся организаций (если showCreateForm=false, то всё равно можно показать, 
          чтобы пользователь мог передумать и выбрать существующую) */}
      {!showCreateForm && orgs && orgs.length > 0 && (
        <Box>
          <Typography>Уже существующие организации:</Typography>
          <Box sx={{ mt: 1 }}>
            {orgs.map((org) => (
              <Button
                key={org.org_id}
                variant={org.org_id === selectedOrgId ? 'contained' : 'outlined'}
                onClick={() => setSelectedOrgId(org.org_id)}
                sx={{ m: 1 }}
              >
                {org.company_name} / {org.industry}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WizardStep1Organization;
