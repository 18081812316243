// frontend/src/components/wizard/WizardStep2Product.js

import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { getProducts, createProduct } from '../../services/api';
import { AppContext } from '../../context/AppContext';

/**
 * Шаг 2: выбор или создание продукта.
 * 
 * Props:
 *  selectedOrgId: string
 *  selectedProductId, setSelectedProductId
 *  products, setProducts
 */
const WizardStep2Product = ({
  selectedOrgId,
  selectedProductId,
  setSelectedProductId,
  products,
  setProducts
}) => {
  const { setNotification } = useContext(AppContext);
  const [createMode, setCreateMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [productData, setProductData] = useState({
    product_name: '',
    about_product: '',
    website: ''
  });

  // Если нужно, можно подгружать продукты здесь через useEffect
  // Но в основном файле может уже вызывался loadProductsForOrg

  const handleCreateProduct = async () => {
    if (!productData.about_product.trim()) {
      setNotification({ type: 'error', text: 'Заполните поле «Описание продукта»' });
      return;
    }
    setLoading(true);
    try {
      const payload = {
        org_id: selectedOrgId,
        product_name: productData.product_name,
        about_product: productData.about_product,
        website: productData.website
      };
      const resp = await createProduct(payload);
      setNotification({ type: 'success', text: 'Продукт создан' });
      setCreateMode(false);
      setSelectedProductId(resp.data.product_id);
      // Обновим список products
      const fresh = await getProducts(selectedOrgId);
      setProducts(fresh.data);
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при создании продукта' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 2: Продукт</Typography>
      
      {selectedOrgId ? (
        <Box sx={{ mt: 2 }}>
          {!createMode && products.length === 0 && (
            <Typography>В этой организации ещё нет продуктов. Создайте новый.</Typography>
          )}
          {!createMode && products.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography>Выберите продукт:</Typography>
              <Box sx={{ mt: 1 }}>
                {products.map((prod) => (
                  <Button
                    key={prod.product_id}
                    variant={prod.product_id === selectedProductId ? 'contained' : 'outlined'}
                    onClick={() => setSelectedProductId(prod.product_id)}
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {prod.product_name || prod.about_product}
                  </Button>
                ))}
              </Box>
            </Box>
          )}

          <Button
            variant="contained"
            color="secondary"
            sx={{ mb: 2 }}
            onClick={() => setCreateMode(!createMode)}
          >
            {createMode ? 'Отмена' : 'Создать новый продукт'}
          </Button>

          {createMode && (
            <Box sx={{ border: '1px solid #ccc', p: 2 }}>
              <TextField
                label="Название продукта (необязательно)"
                fullWidth
                sx={{ mb: 1 }}
                value={productData.product_name}
                onChange={(e) => setProductData({ ...productData, product_name: e.target.value })}
              />
              <TextField
                label="Описание продукта (обязательно)"
                fullWidth
                sx={{ mb: 1 }}
                value={productData.about_product}
                onChange={(e) => setProductData({ ...productData, about_product: e.target.value })}
              />
              <TextField
                label="Ссылка на сайт (необязательно)"
                fullWidth
                sx={{ mb: 2 }}
                value={productData.website}
                onChange={(e) => setProductData({ ...productData, website: e.target.value })}
              />
              <Button variant="outlined" onClick={handleCreateProduct} disabled={loading}>
                {loading ? 'Создаём...' : 'Сохранить продукт'}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Typography>Сначала выберите организацию на предыдущем шаге</Typography>
      )}
    </Box>
  );
};

export default WizardStep2Product;
