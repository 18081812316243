// frontend/src/components/wizard/WizardStep3Design.js

import React, { useState, useContext } from 'react';
import { Box, Typography, Button, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { createResearchFromTemplate } from '../../services/api';
import { AppContext } from '../../context/AppContext';

/**
 * Шаг 3: выбор шаблона / кастомный бриф
 * 
 * Props:
 *  selectedProductId: string
 *  selectedTemplateId, setSelectedTemplateId
 *  customBrief, setCustomBrief
 *  templateDesign, setTemplateDesign
 *  templateName, setTemplateName
 *  templates: список шаблонов [{template_id, template_name, ...}]
 */
const WizardStep3Design = ({
  selectedProductId,
  selectedTemplateId, setSelectedTemplateId,
  customBrief, setCustomBrief,
  templateDesign, setTemplateDesign,
  templateName, setTemplateName,
  templates
}) => {
  const { setNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const handleSelectTemplate = async () => {
    if (!selectedTemplateId) {
      // сброс -> кастом
      setTemplateDesign(null);
      setTemplateName('');
      return;
    }
    if (!selectedProductId) {
      setNotification({ type: 'error', text: 'Сначала выберите продукт' });
      return;
    }
    setLoading(true);
    try {
      const resp = await createResearchFromTemplate(selectedTemplateId, selectedProductId);
      setTemplateDesign(resp.data.default_design);
      setTemplateName(resp.data.template_name);
      setNotification({ type: 'info', text: `Загружен шаблон: ${resp.data.template_name}` });
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: 'Ошибка при загрузке шаблона' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 3: Дизайн исследования</Typography>
      <Typography sx={{ mt: 1 }}>
        Вы можете выбрать готовый шаблон или заполнить кастомный бриф.
      </Typography>

      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel>Шаблон</InputLabel>
        <Select
          value={selectedTemplateId}
          label="Шаблон"
          onChange={(e) => setSelectedTemplateId(e.target.value)}
        >
          <MenuItem value="">(Кастомный бриф)</MenuItem>
          {templates.map((t) => (
            <MenuItem key={t.template_id} value={t.template_id}>
              {t.template_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained" onClick={handleSelectTemplate} disabled={loading} sx={{ mb: 3 }}>
        {loading ? 'Загрузка...' : 'Применить'}
      </Button>

      {/* Кастомный бриф */}
      {!selectedTemplateId && (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <TextField
            label="Что вы хотите узнать?"
            fullWidth
            sx={{ mb: 1 }}
            value={customBrief.rsr_objectives}
            onChange={(e) => setCustomBrief({ ...customBrief, rsr_objectives: e.target.value })}
          />
          <TextField
            label="Какие проблемы/вопросы?"
            fullWidth
            sx={{ mb: 1 }}
            value={customBrief.rsr_problem}
            onChange={(e) => setCustomBrief({ ...customBrief, rsr_problem: e.target.value })}
          />
          <TextField
            label="На какие данные обратить внимание?"
            fullWidth
            sx={{ mb: 1 }}
            value={customBrief.rsr_data}
            onChange={(e) => setCustomBrief({ ...customBrief, rsr_data: e.target.value })}
          />
          <TextField
            label="Как будут использованы результаты?"
            fullWidth
            sx={{ mb: 1 }}
            value={customBrief.rsr_usage}
            onChange={(e) => setCustomBrief({ ...customBrief, rsr_usage: e.target.value })}
          />
        </Box>
      )}

      {/* Предпросмотр шаблона */}
      {selectedTemplateId && templateDesign && (
        <Box sx={{ border: '1px solid #ccc', p: 2 }}>
          <Typography variant="h6">
            Предпросмотр шаблона: {templateName}
          </Typography>
          <pre style={{ background: '#f5f5f5', padding: '1rem', overflowX: 'auto' }}>
            {JSON.stringify(templateDesign, null, 2)}
          </pre>
        </Box>
      )}
    </Box>
  );
};

export default WizardStep3Design;
