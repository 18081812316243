// frontend/src/theme.js

import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: '#5848C4',
      },
      secondary: {
        main: '#49C488',
      },
      background: {
        default: mode === 'dark' ? '#202020' : '#f5f5f5',
        paper: mode === 'dark' ? '#282828' : '#ffffff',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
        secondary: mode === 'dark' ? '#aaaaaa' : '#555555',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#282828' : '#ffffff',
            color: mode === 'dark' ? '#ffffff' : '#000000',
            boxShadow: 'none',
            borderBottom: `1px solid ${mode === 'dark' ? '#333333' : '#e0e0e0'}`,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            bottom: '20px',
            right: '20px',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            transition: 'color 0.3s ease',
          },
        },
      },
    },
  });
};

export default getTheme;
