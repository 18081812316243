import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { AppContext } from '../context/AppContext';
import { getResearchById } from '../services/api';

const ResearchDetail = () => {
  const { researchId } = useParams();
  const { user } = useContext(AppContext);
  const [research, setResearch] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getResearchById(researchId)
      .then((resp) => {
        setResearch(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [user, researchId]);

  if (!user || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress size={50} color="secondary" />
      </Box>
    );
  }

  if (!research) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5">Исследование не найдено или нет доступа</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Детали исследования #{researchId}
      </Typography>
      <Typography>Статус: {research.status}</Typography>
      {/* ... и т.д. выводим поля исследования ... */}
    </Container>
  );
};

export default ResearchDetail;
