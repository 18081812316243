import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import ResearchWizard from './pages/ResearchWizard';
import ResearchDetail from './pages/ResearchDetail';
import TelegramLogin from './pages/TelegramLogin';
import TelegramWebAppAuth from './pages/TelegramWebAppAuth';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import { AppContext } from './context/AppContext';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import Notification from './components/Notification';
import getTheme from './theme';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTelegramWebApp, themeMode, setThemeMode } = useContext(AppContext);
  
  const [currentTheme, setCurrentTheme] = useState('light');

  // Проверка токена и маршрутов при монтировании/изменении пути
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      // Если токен есть, а мы на странице /login или /telegram-webapp-auth, перенаправляем на главную
      if (['/login', '/telegram-webapp-auth'].includes(location.pathname)) {
        navigate('/');
      }
    } else {
      // Если токена нет, проверяем Telegram WebApp
      if (isTelegramWebApp && location.pathname !== '/telegram-webapp-auth') {
        navigate('/telegram-webapp-auth');
      } else if (!isTelegramWebApp && location.pathname !== '/login') {
        navigate('/login');
      }
    }
  }, [navigate, location, isTelegramWebApp]);

  // Функция для определения темы на основе времени суток
  const determineTheme = () => {
    const hour = new Date().getHours();
    return (hour >= 6 && hour < 18) ? 'light' : 'dark';
  };

  // Слежение за переключением темы (auto/light/dark)
  useEffect(() => {
    if (themeMode === 'auto') {
      setCurrentTheme(determineTheme());

      const now = new Date();
      let nextSwitch = new Date();

      if (currentTheme === 'light') {
        // Следующее переключение в 18:00
        nextSwitch.setHours(18, 0, 0, 0);
      } else {
        // Следующее переключение в 6:00
        nextSwitch.setHours(6, 0, 0, 0);
      }

      if (nextSwitch <= now) {
        // Если время уже прошло, прибавим 12 часов
        nextSwitch.setHours(nextSwitch.getHours() + 12);
      }

      const timeout = nextSwitch.getTime() - now.getTime();

      const timer = setTimeout(() => {
        setCurrentTheme(determineTheme());
      }, timeout);

      return () => clearTimeout(timer);
    } else {
      // Если тема не auto, ставим строго 'light' или 'dark'
      setCurrentTheme(themeMode);
    }
  }, [themeMode, currentTheme]);

  // Применение темы
  const theme = useMemo(() => getTheme(currentTheme), [currentTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        {/* Основные роуты приложения */}
        <Routes>
          {/* Публичные роуты */}
          <Route path="/login" element={<TelegramLogin />} />
          <Route path="/telegram-webapp-auth" element={<TelegramWebAppAuth />} />

          {/* Защищённые роуты */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Layout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/research-wizard" element={<ResearchWizard />} />
                    <Route path="/research/:researchId" element={<ResearchDetail />} />
                    {/* Добавьте при необходимости другие маршруты */}
                  </Routes>
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
        {/* Компонент глобальных уведомлений */}
        <Notification />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
