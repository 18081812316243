// frontend/src/components/wizard/WizardStep2Product.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from '../../context/AppContext';
import { updateProduct as apiUpdateProduct } from '../../services/api';

/**
 * Шаг 2: выбор или создание продукта с возможностью редактирования.
 * 
 * Props:
 *  selectedOrgId: string
 *  selectedProductId, setSelectedProductId
 *  products, setProducts
 *  isNewProduct, setIsNewProduct: флаг создания нового продукта
 *  newProductData, setNewProductData: данные для нового продукта
 */
const WizardStep2Product = ({
  selectedOrgId,
  selectedProductId,
  setSelectedProductId,
  products,
  setProducts,
  isNewProduct,
  setIsNewProduct,
  newProductData,
  setNewProductData
}) => {
  const { setNotification } = useContext(AppContext);
  const [editProductId, setEditProductId] = useState(null);
  const [editProductData, setEditProductData] = useState({
    product_name: '',
    about_product: '',
    website: ''
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // Функция для преобразования относительных URL в абсолютные
  const getAbsoluteUrl = (url) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  // Валидация формы создания продукта
  const validateNewProduct = () => {
    const errors = {};
    if (!newProductData.product_name.trim()) {
      errors.product_name = 'Название продукта обязательно и не может состоять только из пробелов.';
    }
    if (!newProductData.about_product.trim()) {
      errors.about_product = 'Описание продукта обязательно.';
    }
    return errors;
  };

  // Валидация формы редактирования продукта
  const validateEditProduct = () => {
    const errors = {};
    if (!editProductData.product_name.trim()) {
      errors.product_name = 'Название продукта обязательно и не может состоять только из пробелов.';
    }
    if (!editProductData.about_product.trim()) {
      errors.about_product = 'Описание продукта обязательно.';
    }
    return errors;
  };

  // Обработка сохранения нового продукта
  const handleSaveNewProduct = async () => {
    const errors = validateNewProduct();
    if (Object.keys(errors).length > 0) {
      // Отображение ошибок
      Object.values(errors).forEach((error) => {
        setNotification({ type: 'error', text: error });
      });
      return;
    }

    // Продолжение создания продукта на фронтенде
    // Этот код уже обрабатывается в родительском компоненте
  };

  // Функция для открытия диалогового окна редактирования продукта
  const handleEditClick = (prod) => {
    setEditProductId(prod.product_id);
    setEditProductData({
      product_name: prod.product_name || '',
      about_product: prod.about_product || '',
      website: prod.website || ''
    });
    setEditDialogOpen(true);
  };

  // Сохранение изменений продукта
  const handleSaveEdit = async () => {
    const errors = validateEditProduct();
    if (Object.keys(errors).length > 0) {
      // Отображение ошибок
      Object.values(errors).forEach((error) => {
        setNotification({ type: 'error', text: error });
      });
      return;
    }

    setEditLoading(true);
    try {
      const resp = await apiUpdateProduct(editProductId, editProductData);
      setNotification({ type: 'success', text: 'Продукт обновлен!' });
      setEditDialogOpen(false);

      // Обновление списка продуктов
      setProducts((prevProducts) =>
        prevProducts.map((prod) =>
          prod.product_id === resp.data.product_id ? resp.data : prod
        )
      );

      // Если обновленный продукт выбран, обновить его ID
      if (resp.data.product_id === selectedProductId) {
        setSelectedProductId(resp.data.product_id);
      }
    } catch (err) {
      console.error(err);
      setNotification({ type: 'error', text: err.response?.data?.detail || 'Ошибка при обновлении продукта' });
    } finally {
      setEditLoading(false);
    }
  };

  // Функция обработки клика по карточке продукта
  const handleCardClick = (product_id) => {
    setSelectedProductId(product_id);
  };

  return (
    <Box>
      <Typography variant="h6">Шаг 2: Продукт</Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Выбор существующего продукта */}
        {!isNewProduct && (
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Выберите существующий продукт:</Typography>
            {products.length > 0 ? (
              products.map((prod) => (
                <Card
                  key={prod.product_id}
                  onClick={() => handleCardClick(prod.product_id)}
                  variant="outlined"
                  sx={{
                    mb: 2,
                    cursor: 'pointer',
                    borderColor: prod.product_id === selectedProductId ? 'primary.main' : 'grey.300',
                    backgroundColor: prod.product_id === selectedProductId ? 'primary.main' : 'transparent',
                    color: prod.product_id === selectedProductId ? 'primary.contrastText' : 'text.primary',
                    position: 'relative',
                  }}
                >
                  {/* Кнопка редактирования в правом верхнем углу */}
                  <IconButton
                    onClick={(e) => { e.stopPropagation(); handleEditClick(prod); }}
                    color={prod.product_id === selectedProductId ? 'primary.contrastText' : 'primary'}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  >
                    <EditIcon />
                  </IconButton>

                  <CardContent>
                    <Typography variant="h6">{prod.product_name}</Typography>
                    <Typography color="inherit">{prod.about_product}</Typography>
                    {prod.website && (
                      <Typography color="inherit">
                        Сайт: <a href={getAbsoluteUrl(prod.website)} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>{prod.website}</a>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>В этой организации ещё нет продуктов.</Typography>
            )}
          </Grid>
        )}

        {/* Переключатель между выбором существующего продукта и созданием нового */}
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Button
            variant={isNewProduct ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => {
              setIsNewProduct(!isNewProduct);
              if (isNewProduct) {
                // Очистка данных при отмене создания нового продукта
                setNewProductData({
                  product_name: '',
                  about_product: '',
                  website: ''
                });
              }
            }}
            fullWidth
          >
            {isNewProduct ? 'Отмена создания продукта' : 'Создать новый продукт'}
          </Button>
        </Grid>

        {/* Форма создания нового продукта */}
        {isNewProduct && (
          <Grid item xs={12}>
            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Название продукта *"
                    fullWidth
                    required
                    value={newProductData.product_name}
                    onChange={(e) => setNewProductData({ ...newProductData, product_name: e.target.value })}
                    error={!newProductData.product_name.trim() && newProductData.product_name !== ''}
                    helperText={!newProductData.product_name.trim() && newProductData.product_name !== '' ? 'Название продукта обязательно и не может состоять только из пробелов.' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Ссылка на сайт (необязательно)"
                    fullWidth
                    value={newProductData.website}
                    onChange={(e) => setNewProductData({ ...newProductData, website: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Описание продукта *"
                    fullWidth
                    required
                    multiline
                    rows={4}
                    value={newProductData.about_product}
                    onChange={(e) => setNewProductData({ ...newProductData, about_product: e.target.value })}
                    error={!newProductData.about_product.trim() && newProductData.about_product !== ''}
                    helperText={!newProductData.about_product.trim() && newProductData.about_product !== '' ? 'Описание продукта обязательно.' : ''}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Диалог редактирования продукта */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Редактирование продукта</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Название продукта *"
                fullWidth
                required
                value={editProductData.product_name}
                onChange={(e) => setEditProductData({ ...editProductData, product_name: e.target.value })}
                error={!editProductData.product_name.trim() && editProductData.product_name !== ''}
                helperText={!editProductData.product_name.trim() && editProductData.product_name !== '' ? 'Название продукта обязательно и не может состоять только из пробелов.' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ссылка на сайт (необязательно)"
                fullWidth
                value={editProductData.website}
                onChange={(e) => setEditProductData({ ...editProductData, website: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Описание продукта *"
                fullWidth
                required
                multiline
                rows={4}
                value={editProductData.about_product}
                onChange={(e) => setEditProductData({ ...editProductData, about_product: e.target.value })}
                error={!editProductData.about_product.trim() && editProductData.about_product !== ''}
                helperText={!editProductData.about_product.trim() && editProductData.about_product !== '' ? 'Описание продукта обязательно.' : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Отмена</Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary" disabled={editLoading}>
            {editLoading ? 'Сохранение...' : 'Сохранить'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WizardStep2Product;
